.login body {
  margin: 0;
  overflow-x: hidden; }

.login .leftBG {
  /* height: 100%; */
  width: 35.42vw;
  position: absolute;
  left: 0;
  top: 0; }

.login .chart {
  width: 41.42vw;
  position: absolute;
  left: 3.2287vw;
  top: 1.38376vw; }

.login .bigCircle {
  position: absolute;
  width: 55.8118vw;
  height: 55.8118vw;
  border-radius: 50%;
  background: #051535;
  margin-top: 1.9372vw;
  right: -6vw; }

.login .logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8.76383vw;
  margin-bottom: 3.98154vw; }

.login .logo {
  width: 4.62794vw;
  height: 4.62794vw;
  margin: 0 0.9074vw 0 0; }

.login .logoInitials {
  height: 4.62794vw; }

.login .form {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .login .form p {
    color: white;
    font-size: 2.952vw;
    font-family: arial;
    margin: 0; }
  .login .form input {
    width: 32.0405vw;
    height: 4.797vw;
    border: none;
    font-size: 1.65898vw;
    margin-bottom: 3.2287vw;
    padding-left: 2vw; }
  .login .form button {
    width: 21.6789vw;
    height: 5.4428vw;
    background: #e7202b;
    color: white;
    font-size: 2.952vw;
    font-family: arial;
    font-weight: 600;
    border: none;
    margin-top: 2vw;
    cursor: pointer;
    transition: 0.5s ease;
    filter: brightness(1); }
    .login .form button:hover {
      filter: brightness(1.2);
      color: #e7202b;
      background: white; }
  .login .form .QR {
    display: none; }

.login .rightsReserved {
  display: none; }

@media (orientation: portrait) {
  .login body {
    margin: 0;
    overflow-x: hidden;
    background-size: 100% 100vh;
    background-image: url("/images/login/mobBG.jpg");
    /* width: 100%; */ }
  .login .leftBG {
    /* height: 100%; */
    width: 35.42vw;
    position: absolute;
    left: 0;
    top: 0;
    display: none; }
  .login .chart {
    width: 41.42vw;
    position: absolute;
    left: 3.2287vw;
    display: none;
    top: 1.38376vw; }
  .login .bigCircleContainer {
    position: relative;
    width: 100%;
    overflow: hidden; }
  .login .bigCircle {
    position: relative;
    width: 126vw;
    height: 126vw;
    border-radius: 50%;
    background: #051045;
    margin-top: 19.9372vw;
    /* right: -123vw; */
    left: -13vw;
    padding: 0.1px; }
  .login .logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12.76383vw;
    margin-bottom: 9.98154vw; }
  .login .logo {
    width: 10.6666vw;
    height: 10.6666vw;
    margin: 0 0.9074vw 0 0; }
  .login .logoInitials {
    height: 10.6666vw; }
  .login .form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .login .form p {
      color: white;
      font-size: 4.26666vw;
      font-family: arial;
      margin: 0;
      margin-bottom: 2vw; }
    .login .form input {
      width: 73.2vw;
      height: 10.4vw;
      border: none;
      font-size: 4.8vw;
      margin-bottom: 7.2287vw; }
    .login .form button {
      width: 34.666vw;
      height: 8.79999vw;
      background: #e7202b;
      color: white;
      font-size: 4.266666vw;
      font-family: arial;
      font-weight: 600;
      border: none;
      margin-top: 2vw;
      cursor: pointer;
      transition: 0.5s ease;
      filter: brightness(1); }
      .login .form button:hover {
        filter: brightness(1.2); }
    .login .form .QR {
      display: block;
      width: 19.2vw;
      margin-top: 7vw; }
    .login .form .form form .rightsReserved {
      display: block;
      text-align: center;
      color: white;
      font-family: "AvenirLTStd-Light";
      font-size: 3.2vw;
      position: absolute;
      width: 100%;
      bottom: 6vw; } }
